import { backendServer } from "./serverDetection"
import ReactGA from "react-ga4";
const dbCall = async (event) => {
  try {
    fetch(backendServer + '/api?model=analytics&path=dump', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ event, visitorId: window.visitorId }),
    })
  } catch (error) {
    console.log(error)
  }
}

const gaCall = async (event) => {
  try {
    ReactGA.event(event);


  } catch (error) {
    console.log(error)
  }
}

export const analyticsCall = async (event) => {
  gaCall(event)
  dbCall(event)
}