import { useState } from "react";

const Scheduler = () => {
    const [progress, setProgress] = useState(0);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        service: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const isStepOneComplete = () => {
        return formData.firstName && formData.lastName && formData.email && formData.phone;
    };

    const isStepTwoComplete = () => {
        return formData.address && formData.city && formData.state && formData.zip && formData.service;
    };

    const handleContinueClick = () => {
        if ((progress === 0 && isStepOneComplete()) || (progress === 1 && isStepTwoComplete())) {
            setProgress(progress + 1);
        }
    };

    return (
        <>
            <div className="modal-header">
                <h1>Schedule Consultation</h1>
                <div className="status-tabs">
                    <span className={`status-tab ${progress === 0 ? 'active-tab' : ''}`}>Your Info</span>
                    <span className={`status-tab ${progress === 1 ? 'active-tab' : ''}`}>Your Property</span>
                    <span className={`status-tab ${progress === 2 ? 'active-tab' : ''}`}>Schedule Time</span>
                </div>
            </div>
            <div className="modal-content">
                <form className="flyby-form">
                    <div style={{ display: progress === 0 ? 'flex' : 'none' }} className="form-wrapper vivify fadeIn duration-300">
                        <div className="flyby-form-group">
                            <div className="flyby-input">
                                <label htmlFor="first-name">First Name*</label>
                                <input placeholder="Enter First Name" type="text" name="firstName" id="first-name" value={formData.firstName} onChange={handleInputChange} />
                            </div>
                            <div className="flyby-input">
                                <label htmlFor="last-name">Last Name*</label>
                                <input placeholder="Enter Last Name" type="text" name="lastName" id="last-name" value={formData.lastName} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="flyby-input">
                            <label htmlFor="phone">Phone Number*</label>
                            <input placeholder="Enter Phone Number" type="tel" name="phone" id="phone" value={formData.phone} onChange={handleInputChange} />
                        </div>
                        <div className="flyby-input">
                            <label htmlFor="email">Work Email*</label>
                            <input placeholder="Enter Email" type="email" name="email" id="email" value={formData.email} onChange={handleInputChange} />
                        </div>

                    </div>

                    <div style={{ display: progress === 1 ? 'flex' : 'none' }} className="form-wrapper vivify fadeIn duration-300">

                        <div className="flyby-input">
                            <label htmlFor="address">Address</label>
                            <input placeholder="Enter Address" type="text" name="address" id="address" value={formData.address} onChange={handleInputChange} />
                        </div>
                        <div className="flyby-form-group">
                            <div className="flyby-input">
                                <label htmlFor="city">City</label>
                                <input placeholder="Enter City" type="text" name="city" id="city" value={formData.city} onChange={handleInputChange} />
                            </div>
                            <div className="flyby-input">
                                <label htmlFor="state">State</label>
                                <input placeholder="Enter State" type="text" name="state" id="state" value={formData.state} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="flyby-input">
                            <label htmlFor="zip">Zip Code</label>
                            <input placeholder="Enter Zip Code" type="text" name="zip" id="zip" value={formData.zip} onChange={handleInputChange} />
                        </div>
                        <div className="flyby-input">
                            <label htmlFor="service">Service Needed</label>
                            <select name="service" id="service" value={formData.service} onChange={handleInputChange}>
                                <option value="" disabled>Select Service</option>
                                <option value="Drone Soft Wash/Pressure Wash">Drone Soft Wash/Pressure Wash</option>
                                <option value="Dumpster Pad Cleaning">Dumpster Pad Cleaning</option>
                                <option value="Multi-Story Window Cleaning">Multi-Story Window Cleaning</option>
                                <option value="Parking Lot Cleaning">Parking Lot Cleaning</option>
                                <option value="Window Cleaning">Window Cleaning</option>
                                <option value="Industrial Cleaning">Industrial Cleaning</option>
                                <option value="Monument Restoration">Monument Restoration</option>
                                <option value="Oil Terminal Tank Exterior Cleaning">Oil Terminal Tank Exterior Cleaning</option>
                                <option value="Stadium and Dome Cleaning">Stadium and Dome Cleaning</option>
                                <option value="Water Tower Cleaning">Water Tower Cleaning</option>
                                <option value="Residential Soft Wash & Pressure Wash">Residential Soft Wash & Pressure Wash</option>
                                <option value="Roof Cleaning">Roof Cleaning</option>
                                <option value="Gutter Cleaning">Gutter Cleaning</option>
                            </select>
                        </div>

                    </div>
                    <div className="button-grouper">
                        {progress > 0 && <button type="button" onClick={() => setProgress(progress - 1)} className="bulk-btn-minimal">
                            <i className="material-icons-round">west</i>
                            Previous
                        </button>}
                        {progress < 2 && <button type="button" onClick={handleContinueClick} className={
                            `bulk-btn ${progress === 0 && !isStepOneComplete() ? 'disabled-btn' : ''} ${progress === 1 && !isStepTwoComplete() ? 'disabled-btn' : ''}`
                        } disabled={(progress === 0 && !isStepOneComplete()) || (progress === 1 && !isStepTwoComplete())}>
                            Continue <i className="material-icons-round">east</i>
                        </button>}
                        {progress === 2 && <button className="bulk-btn">Submit</button>}
                    </div>
                </form>
            </div>
        </>
    );
}

export default Scheduler;
